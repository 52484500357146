import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import Link from "next/link";

import { STRAIN_MEDICAL_DISCLAIMER_US } from "constants/disclaimers";
import { FLAVORS_EFFECTS_DISCLAIMER } from "constants/strains";
import { useStrainPageContext } from "context/StrainPageContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { extractStrainSensations } from "utils/extractStrainSensations";
import isCanada from "utils/isCanada";

import ChatIcon from "components/Icons/chat.svg";
import EffectIcon from "components/Icons/EffectIcon";
import IconSvg from "components/IconSvg";
import MedicalDisclaimers from "components/MedicalDisclaimers";
import TrackImpression from "components/TrackImpression";

import { createStrainSensationsSchema } from "./createStrainSensationsSchema";
import StrainSensationTile from "./StrainSensationTile";
import TooltipCTA from "./TooltipCTA";

type StrainSensationsProps = {
  className?: string;
  children?: React.ReactElement;
};
const StrainSensations: React.FC<StrainSensationsProps> = ({
  className = "",
  children,
}): JSX.Element => {
  const { strain } = useStrainPageContext();
  const countryCode = useDomainCountryCode();

  if (isEmpty(strain)) {
    return <></>;
  }

  const feelings = extractStrainSensations(strain.effects).slice(0, 3);
  const helpsWith = extractStrainSensations(
    {
      ...strain.symptoms,
      ...strain.conditions,
    },
    "votes",
  ).slice(0, 3);
  const negatives = strain.negatives
    ? extractStrainSensations(strain.negatives).slice(0, 3)
    : [];
  const flavors = strain.flavors
    ? extractStrainSensations(strain.flavors).slice(0, 3)
    : [];

  const schema = createStrainSensationsSchema(
    strain.name,
    strain.traitsSourceCountTotal || 0,
    feelings,
    helpsWith,
    negatives,
  );

  return feelings.length > 0 ||
    helpsWith.length > 0 ||
    negatives.length > 0 ||
    flavors.length > 0 ? (
    <TrackImpression
      category="Strain Effects"
      label="effects section"
      id="strain-sensations-section"
      className={`my-[32px] ${className}`}
    >
      {(feelings.length > 0 ||
        helpsWith.length > 0 ||
        negatives.length > 0) && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
          key="strain-sensations-schema"
        />
      )}

      <h2 className="text-md mb-sm">{strain.name} strain effects</h2>
      <div className="row mt-lg">
        {(feelings.length > 0 ||
          negatives.length > 0 ||
          flavors.length > 0) && (
          <div className="col md:col-1/2 md:pr-xxl">
            {(feelings.length > 0 || negatives.length > 0) && (
              <div className="mb-xxl">
                <TooltipCTA
                  sectionName="effects"
                  title={`Reported by ${strain.reviewCount} real people like you`}
                  tooltipText={FLAVORS_EFFECTS_DISCLAIMER}
                  icon={<ChatIcon height="24" width="24" />}
                  shouldShowTooltip={!isCanada(countryCode)}
                  maxWidth="100%"
                />
                {feelings.length ? (
                  <div className="mb-lg">
                    <h3 className="font-extrabold uppercase mb-xs text-xs">
                      Feelings
                    </h3>
                    <div className="row">
                      {feelings.map((feeling) => {
                        const slug = kebabCase(feeling.name);
                        return (
                          <div className="col-1/3" key={slug}>
                            <StrainSensationTile
                              title={feeling.name}
                              eventLabel="effect tile"
                              link={`/strains/lists/effect/${slug}`}
                              icon={
                                <EffectIcon
                                  width="65"
                                  height="34"
                                  effect={slug}
                                />
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {negatives.length ? (
                  <div>
                    <h3 className="font-extrabold uppercase mb-xs text-xs">
                      Negatives
                    </h3>
                    <div className="row">
                      {negatives.map((negative) => {
                        const slug = kebabCase(negative.name);
                        return (
                          <div className="col-1/3" key={slug}>
                            <StrainSensationTile
                              title={negative.name}
                              eventLabel="avoid tile"
                              link={`/strains?filter%5Btop_strain_negatives_excluded%5D=${slug}`}
                              icon={
                                <IconSvg
                                  width="65"
                                  height="34"
                                  filePath={`effects/${slug}.svg`}
                                />
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            {flavors.length > 0 && (
              <div>
                <h2 className="text-md mb-sm">{strain.name} strain flavors</h2>
                <div className="row">
                  {flavors.map((flavor) => {
                    const slug = kebabCase(flavor.name);
                    return (
                      <div className="col-1/3" key={slug}>
                        <StrainSensationTile
                          title={flavor.name}
                          eventLabel="effect tile"
                          link={`/strains/lists/flavor/${slug}`}
                          icon={
                            <IconSvg
                              width="65"
                              height="34"
                              filePath={`flavors/${slug}.svg`}
                            />
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        {helpsWith.length > 0 && (
          <div
            id="helps-with-section"
            className="col md:col-1/2 md:pl-xxl mt-xxl md:mt-none md:border-l border-light-grey"
          >
            <h2 className="text-md mb-sm">{strain.name} strain helps with</h2>
            <ul>
              {helpsWith.map(({ name, votes }) => {
                const slug = kebabCase(name);
                const percentage = strain.traitsSourceCountTotal
                  ? Math.floor((votes / strain.traitsSourceCountTotal) * 100)
                  : 0;
                return (
                  <li className="mb-xl" key={slug}>
                    <div className="flex items-center mb-xs">
                      <Link
                        href={`/strains/lists/condition/${slug}`}
                        prefetch={false}
                        onClick={() =>
                          trackEvent("Strain Effects", "Click", "helps with")
                        }
                        className="font-bold underline"
                      >
                        {name}
                      </Link>
                    </div>
                    <div className="text-xs lowercase">
                      <span className="font-bold">{`${percentage}% `}</span>
                      of people say it helps with {name}
                    </div>
                  </li>
                );
              })}
            </ul>
            <MedicalDisclaimers usDisclaimer={STRAIN_MEDICAL_DISCLAIMER_US} />
            {children}
          </div>
        )}
      </div>
    </TrackImpression>
  ) : (
    <></>
  );
};

export default StrainSensations;
